<template>



<div class="d-grid gap-3">
  <div>&nbsp;</div>
</div>  


<div class="container overflow-hidden text-center">

  <hr class="hr" />  

  <div class="d-grid gap-3">
    <div>&nbsp;</div>
  </div> 


  <!--Section: Content-->
  <section class="text-center">
    <div class="row">
      
      <div class="mb-5">
        <h4><strong>Product Pipeline</strong></h4><br/>
        <p class="card-text">
          We are currently at the pre-production phase, with lots of buildups happening within company's processes and systems.
        </p>
        <p class="card-text">
          Details of our Kickstarter preparation is in <a href="https://huttstudio.com/" target="_blank" class="text-primary">Hutt Studio</a>'s page.
        </p>
        <p class="card-text">
          We also have sustainability campaign run through <a href="https://simplexity101.com/" target="_blank" class="text-primary">Simplexity 101</a>.
        </p>
        <br/>
        <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
            <img
              src="https://cdn.simplexitydynamics.com/images/current-status.png"
              class="img-fluid"
            />
            
          </div>
        
      </div>
    </div>    
  </section>
  <!--Section: Content-->

  <div class="d-grid gap-3">
    <div>&nbsp;</div>
  </div> 

  <hr class="hr" />  

</div>


<div class="d-grid gap-3">
  <div>&nbsp;</div>
</div>  

</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>