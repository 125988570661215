<template>
<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarExample01"
        aria-controls="navbarExample01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarExample01">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <a class="navbar-brand mt-2 mt-lg-0" href="#">
            <img
              src="https://cdn.simplexitydynamics.com/icons/sd/favicon-32x32.png"
              height="32"
              alt="Simplexity Dynamics Logo"
              loading="lazy"
            />
          </a>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">About</a>
          </li>
          -->
        </ul>
      </div>
 
    </div>
  </nav>
  <!-- Navbar -->

  <!-- Jumbotron -->
  <div class="p-5 text-center bg-light bg-image1">
    <h1 class="mb-3 text-light">Simplexity Dynamics</h1>
    <h4 class="mb-3">&nbsp;</h4>
    <p class="mb-3 text-light">
    Our company is building software services, products, and solutions, from simple to complex, 
    <br/>foundational, transitional, to future states of challenges and opportunities.
    </p>
    <p class="mb-3 text-light">
    We are, but not limited to, builders, explorers, and commanders 
    <br/>going through the needs and evolutions of arts, technologies, and wellbeing.
    </p>
    <p class="mb-3 text-light">
    Currently, we are building our foundation, team, processes, and funding.
    </p>
  </div>
  <!-- Jumbotron -->  
</template>
  
<script>
export default {
    name: 'HeaderMain',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
.bg-image1 {
    background-image: url('https://cdn.simplexitydynamics.com/images/sd/abstract-g.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* background: linear-gradient(
        45deg,
        hsla(170, 15%, 30%, 0.7),
        hsla(263, 50%, 10%, 0.7) 70%
      ); */
}
</style>
  