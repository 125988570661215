<template>
   <header >
    <HeaderMain />
  </header>
  <nav>
    
  </nav>
  
  <main>
    <HomePage msg="Hutt Studio"/>
  </main>

  <footer>
    <NavFooter />
  </footer>
</template>

<script>
import HomePage from './components/HomePage.vue'
import HeaderMain from './components/HeaderMain.vue'
// import NavTop from './components/NavTop.vue'
import NavFooter from './components/NavFooter.vue'

export default {
  name: 'App',
  components: {
    HomePage,
    HeaderMain,
    // NavTop,
    NavFooter

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}


</style>
